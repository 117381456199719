import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Partages',
    component: () => import('../views/Partages.vue'),
  },
  {
    path: '/partage/:id',
    name: 'Partage',
    component: () => import('../views/Partages.vue'),
    // props : {
    //   id: route => ({ search: route.params.id })      
    // }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
