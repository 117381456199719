import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import 'bulma';
import slugify from 'slugify';
import md5 from 'crypto-js/md5';
// import Parse from 'parse';
import { Loading } from './mixins/loading'
Vue.mixin(Loading);
import { Outils } from './mixins/outils'
Vue.mixin(Outils);

Vue.prototype.$bus = new Vue();
/*
window.Parse = Parse;
window.Parse.initialize("Fe1gMpk3yMmGdHIilInFpG4TCZkOW3qol3J2tyRB", "FZ42tIQaitjPqlZe5inXIJ0kkcGeS1cFAfuqziCa", "aOtMTA8KbVIuOwX3lRk2T8u6G2EoFXykSbhNsaRX")
window.Parse.serverURL = 'https://parseapi.back4app.com/';
*/
window.md5 = md5;
window.slugify = slugify;

Vue.config.productionTip = false

window.tools = axios.create({
	baseURL: 'https://tools.sopress.net/'
});

window.api = Vue.prototype.api = axios.create({
	baseURL: process.env.VUE_APP_API,
	headers: {
		'Authorization': process.env.VUE_APP_SOGEST_SECRET,
	}
});
/*window.backend = axios.create({
	headers : {
        "X-Parse-Application-Id": "Fe1gMpk3yMmGdHIilInFpG4TCZkOW3qol3J2tyRB",
        "X-Parse-REST-API-Key": "gNuqMn93NtYFg1NfEjpRGpJPl15NBut5p7Jeu5lZ"
	},
	baseURL: process.env.VUE_APP_BACKEND_URL

});*/


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
