export const Loading = {
    data() {
        return {
            loading: false
        }
    },
    mounted() { 
        this.loading=false;
    },
    computed: {
        loadingClass() {
            return this.loading ? 'is-loading' : '';
        }
    },
    methods: {
        loadingStart() {
            this.loading = true;
        },
        loadingEnd() {
            setTimeout(() => {
                this.loading = false;
            },1000)
        }
    }
}