import { applicationId } from 'parse';
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    key: 'partages',
    reducer(state, path) {
      let reducer = Object.assign({}, state)
      delete reducer.partages;
      delete reducer.partage;
      return reducer;
    }
  })],
  state: {
    partages: false,
    partage: false,
    session: false,
    user: false
  },
  mutations: {
    partages(state, partages) {
      state.partages = partages;
    },
    partage(state, partage) {
      state.partage = partage;
    },
    resetUser(state) {
      state.session = false;
      state.user = false;
    },
    setSession(state, data) {
      state.session = data.session_id;
      state.user = data.user;
    }
  },
  getters: {
    isConnected(state) {
      return state.user ? true : false;
    },
    partage(state) {
      return state.partage
    },
    partageId(state) {
      return state.partage ? state.partage.id : false;
    },
    partages(state) {
      return state.partages
    }
  },
  actions: {
    chercherPartages({ commit }, terme) {
      backend.get("Partages", { params: { "where": { "titre": { "$regex": '(?i)' + terme } }, order: 'id', limit: 5 } }).then((response) => {
        commit('partages', response.data.results);
      });
    },
    chargerPartages({ commit, dispatch }, id = false) {
      api.get('table', { params: { nom: 'partages', sort : 'id desc' } }).then(response => {
        commit('partages', response.data);
        if (id) {
          dispatch('chargerPartage', { id });
        }
      })
      /*      backend.get("Partages", { params: { order: 'id', limit: 30 } }).then((response) => {
        commit('partages', response.data.results);
        if (id) {
          dispatch('chargerPartage', { id });
        }
      });*/
    },
    chargerPartage({ commit }, params) {
      let id = 'id' in params ? params.id : false;
      let callback = 'callback' in params ? params.callback : false;
      commit('partage', false);
      if (id) {
        console.log(id)
        api.get('table', { params: { nom: 'partages', where: { id } } }).then(response => {
          let partage = response.data.pop();
          commit('partage', partage);
          if (callback) {
            callback();
          }
        })
        // backend
        //   .get("Partages", {
        //     params: { where: { id: id } },
        //   })
        //   .then((response) => {
        //     let partage = response.data.results.pop();
        //     commit('partage', partage);
        //     if (callback) {
        //       callback();
        //     }
        //   })
      } else {
        if (callback) {
          callback();
        }
      }
    },

  },
  modules: {
  }
})
